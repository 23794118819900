import React, { useEffect, useState, useRef } from 'react'
import './cart.css'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaRupeeSign } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from 'react-icons/bi'
import api from '../../api/api';
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { ActionTypes } from '../../model/action-type';
import EmptyCart from '../../utils/zero-state-screens/Empty_Cart.svg'
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';

const Cart = () => {

    const closeCanvas = useRef();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const cart = useSelector(state => (state.cart))
    const city = useSelector(state => (state.city))
    const sizes = useSelector(state => (state.productSizes))
    const setting = useSelector(state => (state.setting))

    const [productSizes, setproductSizes] = useState(null)
    const [subTotal, setsubTotal] = useState(null)

    const [iscartEmpty, setiscartEmpty] = useState(false)
    const iscartItems=JSON.parse(localStorage.getItem('cartItems'))
    // localStorage.setItem("cartItemNew",JSON.stringify(iscartItems))
    const [isLoader, setisLoader] = useState(false)

    useEffect(() => {
        if (sizes.sizes === null || sizes.status === 'loading') {
            if (city.city !== null && cart.cart !== null) {
                api.getProductbyFilter(city.city.id, city.city.latitude, city.city.longitude)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === 1) {
                            setproductSizes(result.sizes)
                            dispatch({ type: ActionTypes.SET_PRODUCT_SIZES, payload: result.sizes })
                        }
                    })
            }
        }
        else {
            setproductSizes(sizes.sizes)
        }


        

    if(iscartItems){
        // console.log(iscartItems)
        setiscartEmpty(false)
    }else if (cart.cart === null && cart.status === 'fulfill' ||cart.status === 1  ) {
            setiscartEmpty(true)
        }
        else {
            setiscartEmpty(false)
        }

    }, [cart])



var subtotal=0;
iscartItems?.map((item,index)=>(
    subtotal=subtotal + item.product.variants[0].price*item.qty
))


    //Add to Cart
    const addtoCart = async (product_id, product_variant_id, qty) => {
        setisLoader(true)
        await api.addToCart(cookies.get('jwt_token'), product_id, product_variant_id, qty)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    toast.success(result.message)
                    await api.getCart(cookies.get('jwt_token'), city.city.latitude, city.city.longitude)
                        .then(resp => resp.json())
                        .then(res => {
                            setisLoader(false)
                            if (res.status === 1)
                            dispatch({ type: ActionTypes.SET_CART, payload: res })
                        })
                        await api.getCart(cookies.get('jwt_token'), city.city.latitude, city.city.longitude, 1)
                        .then(resp => resp.json())
                        .then(res => {
                            setisLoader(false)
                            if (res.status === 1)
                            dispatch({ type: ActionTypes.SET_CART_CHECKOUT, payload: res.data })
                            
                        })
                        .catch(error => {
                            
                            setisLoader(false)
                            console.log(error)})
                }
                else {
                    setisLoader(false)
                    toast.error(result.message)
                }
            })
    }

    //remove from Cart
const removefromCartNew =async(product_id)=>{
    const productIdToDelete = product_id; // Replace with the product_id you want to delete
// console.log("test",productIdToDelete);
    // Use the filter method to remove the object with the specified product_id
    const updatedData = JSON.parse(localStorage.getItem('cartItems')).filter(item => item.product_id !== productIdToDelete);
    // console.log(updatedData,"test sd");
    localStorage.setItem('cartItems', JSON.stringify(updatedData))
    toast.success("Item updated to users cart successfully")
    setTimeout(() => {
        window.location.reload();
    }, 200)
}
const updateQtyPlus = async (product_id,qty) =>{
    const productIdToUpdate = product_id; 
const newQty = qty+1; 

const updatedData = JSON.parse(localStorage.getItem('cartItems')).map(item => {
  if (item.product_id === productIdToUpdate) {
    
    item.qty = newQty;
  }
  return item;
});
localStorage.setItem('cartItems', JSON.stringify(updatedData))
// toast.success("Item updated to users cart successfully")
setTimeout(() => {
    window.location.reload();
}, 200)
}

const updateQtyMinus = async (product_id,qty) => {
    const productIdToUpdate = product_id; 
    const newQty = qty-1; 
    // const newPrice = price*newQty; 
    
    
    const updatedData = JSON.parse(localStorage.getItem('cartItems')).map(item => {
      if (item.product_id === productIdToUpdate) {
        
        item.qty = newQty;
        item.product.variants[0].price = item.product.variants[0].price*newQty; 
      }
      return item;
    });
    localStorage.setItem('cartItems', JSON.stringify(updatedData))
    // toast.success("Item updated to users cart successfully")
    setTimeout(() => {
        window.location.reload();
    }, 200)
}
    const removefromCart = async (product_id, product_variant_id) => {
        setisLoader(true)
        await api.removeFromCart(cookies.get('jwt_token'), product_id, product_variant_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    toast.success(result.message)
                    await api.getCart(cookies.get('jwt_token'), city.city.latitude, city.city.longitude)
                        .then(resp => resp.json())
                        .then(res => {

                            if (res.status === 1){
                                dispatch({ type: ActionTypes.SET_CART, payload: res })
                        }
                            else{
                                dispatch({ type: ActionTypes.SET_CART, payload: null })
                            }
                        })
                        .catch(error => console.log(error))
                    await api.getCart(cookies.get('jwt_token'), city.city.latitude, city.city.longitude, 1)
                        .then(resp => resp.json())
                        .then(res => {
                            setisLoader(false)
                            if (res.status === 1)
                                dispatch({ type: ActionTypes.SET_CART_CHECKOUT, payload: res.data })


                        })
                        .catch(error => console.log(error))
                }
                else {
                    setisLoader(false)
                    toast.error(result.message)
                }
            })
            .catch(error => console.log(error))
    }
    const {t} = useTranslation();
    const placeHolderImage = (e) =>{
        console.log(e.target.src)
        e.target.src = setting.setting?.web_logo
    }
    return (
        <div tabIndex="-1" className={`cart-sidebar-container offcanvas offcanvas-end`} id="cartoffcanvasExample" aria-labelledby="cartoffcanvasExampleLabel">

            <div className='cart-sidebar-header'>
                <h5>{t("your_cart")}</h5>
                <button type="button" className="close-canvas" data-bs-dismiss="offcanvas" aria-label="Close" ref={closeCanvas}><AiOutlineCloseCircle /></button>
            </div>

            {iscartEmpty ? (
                
                <div className='empty-cart'>
                    <img src={EmptyCart} alt='empty-cart'  onError={placeHolderImage}></img>
                    <p>{t("empty_cart_list_message")}</p>
                    <span>{t("empty_cart_list_description")}</span>
                    <button type='button' className="close-canvas" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {
                        navigate('/products')
                    }}>{t("empty_cart_list_button_name")}</button>
                </div>) : (
                <>
                
                 
                
                    {cart.cart === null
                        ? (<>
                            <div className='cart-sidebar-product'>
                                <div className='products-header'>
                                    <span>{t("product")}</span>
                                    <span>{t("price")}</span>
                                </div>
                                <div className='products-container'>


                                        {iscartItems?.map((items, index) => (
                                            <div key={index} className='cart-card'>
                                                <div className='left-wrapper'>
                                                    <div className='image-container'>
                                                        <img src={items.product.image_url} alt='product'  onError={placeHolderImage}></img>
                                                    </div>

                                                    <div className='product-details'>

                                                        <span>{items.product.name}</span>

                                                        <div id={`selectedVariant${index}-wrapper-cartsidebar`} className='selected-variant-cart' >
                                                            {items.product.variants[0].measurement} {items.product.variants[0].stock_unit_name}
                                                        </div>


                                                        <div className='counter'>
                                                            <button type='button' onClick={() => {
                                                                if (items.qty > 1) {
                                                                    console.log("updateQtyMinus");
                                                                    // addtoCart(items.product.product_id, items.product.product_variant_id, items.qty-1)
                                                                    updateQtyMinus(items.product_id,items.qty)
                                                                }else{
                                                                    removefromCartNew(items.product_id)
                                                                }

                                                            }}><BiMinus fill='#fff' /></button>
                                                            <span id={`input-cart-sidebar${index}`} >{items.qty}</span>
                                                            <button type='button' onClick={() => {
                                                                updateQtyPlus(items.product_id,items.qty)
                                                                // if (val < items.product.total_allowed_quantity) {
                                                                //     document.getElementById(`input-cart-sidebar${index}`).innerHTML = val + 1;
                                                                //     addtoCart(items.product.product_id, items.product.product_variant_id, document.getElementById(`input-cart-sidebar${index}`).innerHTML)
                                                                // }
                                                                if (items.product.is_unlimited_stock === 1) {
                                                                    // console.log(items.product.is_unlimited_stock,"trt unlimitest")
                                                                    if (Number(items.qty) < Number(setting.setting.max_cart_items_count)) {
                                                                        console.log("test minus");
                                                                        // addtoCart(items.product.product_id, items.product.product_variant_id, items.qty + 1)
                                                                        
                                                                        



                                                                    } else {
                                                                        toast.error('Apologies, maximum product quantity limit reached!')
                                                                    }
                                                                } else {
                                                                    if (Number(items.qty) >= Number(items.product.stock)) {
                                                                        toast.error(t("out_of_stock_message"))

                                                                    } else if (Number(items.qty) >= Number(setting.setting.max_cart_items_count)) {
                                                                        toast.error('Apologies, maximum product quantity limit reached!')
                                                                    }
                                                                    else {
                                                                       
                                                                        // addtoCart(items.product.product_id, items.product.product_variant_id, items.qty + 1)
                                                                    }
                                                                }
                                                            }}><BsPlus fill='#fff' /></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='cart-card-end'>
                                                    <div className='d-flex align-items-center' style={{ fontSize: "14px", color:"var(--secondary-color)"}}>
                                                        {setting.setting && setting.setting.currency} <span id={`price${index}-cart-sidebar`}>{items.product.variants[0].price*items.qty}
                                                        </span>
                                                    </div>

                                                    <button type='button' id={`id=${items.product_id}`}className='remove-product' onClick={() => removefromCartNew(items.product_id)}>{t("delete")}</button>

                                                </div>
                                            </div>
                                        ))}


                                    </div>
                            </div>
                            <div className='cart-sidebar-footer'>
                                    

                                   
                                            <>
                                                <div className='summary'>
                                                    <div className='d-flex justify-content-between'>
                                                        <span>{t("sub_total")}</span>
                                                        <div className='d-flex align-items-center' style={{ fontSize: "14px"}}>
                                                            {setting.setting && setting.setting.currency}
                                                            <span>{(subtotal.toFixed(setting.setting?.decimal_point))}</span>
                                                        </div>
                                                    </div>

                                                    
                                                </div>

                                                


                                                <div className='button-container'>
                                                    {/* <button type='button' className='view-cart' onClick={() => {
                                                        closeCanvas.current.click()
                                                        navigate('/cart')
                                                    }}>{t("view_cart")}</button> */}
                                                    <button type='button' className='checkout' data-bs-toggle="modal" data-bs-target="#loginModal">{t("proceed_to_checkout")}</button>
                                                </div>
                                            </>


                                </div>
                            </>
                            // <Loader width='100%' height='100%' />
                        )
                        : (
                            <>
                                {/* {isLoader ? <Loader screen='full' background='none' /> : null} */}
                                <div className='cart-sidebar-product'>
                                    <div className='products-header'>
                                        <span>{t("product")}</span>
                                        <span>{t("price")}</span>
                                    </div>

                                    <div className='products-container'>


                                        {cart.cart.data.cart.map((product, index) => (
                                            <div key={index} className='cart-card'>
                                                <div className='left-wrapper'>
                                                    <div className='image-container'>
                                                        <img src={product.image_url} alt='product'  onError={placeHolderImage}></img>
                                                    </div>

                                                    <div className='product-details'>

                                                        <span>{product.name}</span>

                                                        <div id={`selectedVariant${index}-wrapper-cartsidebar`} className='selected-variant-cart' >
                                                            {product.measurement} {product.unit}
                                                        </div>


                                                        <div className='counter'>
                                                            <button type='button' onClick={() => {
                                                                if (product.qty > 1) {
                                                                    addtoCart(product.product_id, product.product_variant_id, product.qty-1)

                                                                }

                                                            }}><BiMinus fill='#fff' /></button>
                                                            <span id={`input-cart-sidebar${index}`} >{product.qty}</span>
                                                            <button type='button' onClick={() => {
                                                                
                                                                // if (val < product.total_allowed_quantity) {
                                                                //     document.getElementById(`input-cart-sidebar${index}`).innerHTML = val + 1;
                                                                //     addtoCart(product.product_id, product.product_variant_id, document.getElementById(`input-cart-sidebar${index}`).innerHTML)
                                                                // }
                                                                if (product.is_unlimited_stock === 1) {
                                                                    if (Number(product.qty) < Number(setting.setting.max_cart_items_count)) {
                                                                        addtoCart(product.product_id, product.product_variant_id, product.qty + 1)
                                                                    } else {
                                                                        toast.error('Apologies, maximum product quantity limit reached!')
                                                                    }
                                                                } else {
                                                                    if (Number(product.qty) >= Number(product.stock)) {
                                                                        toast.error(t("out_of_stock_message"))

                                                                    } else if (Number(product.qty) >= Number(setting.setting.max_cart_items_count)) {
                                                                        toast.error('Apologies, maximum product quantity limit reached!')
                                                                    }
                                                                    else {
                                                                        addtoCart(product.product_id, product.product_variant_id, product.qty + 1)
                                                                    }
                                                                }
                                                            }}><BsPlus fill='#fff' /></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='cart-card-end'>
                                                    <div className='d-flex align-items-center' style={{ fontSize: "14px", color:"var(--secondary-color)"}}>
                                                        {setting.setting && setting.setting.currency} <span id={`price${index}-cart-sidebar`}> {(product.discounted_price ==0 ? (product.price * product.qty).toFixed(setting.setting && setting.setting.decimal_point)  : (product.discounted_price * product.qty).toFixed(setting.setting && setting.setting.decimal_point))}</span>
                                                    </div>

                                                    <button type='button' className='remove-product' onClick={() => removefromCart(product.product_id, product.product_variant_id)}>{t("delete")}</button>

                                                </div>
                                            </div>
                                        ))}


                                    </div>
                                </div>

                                <div className='cart-sidebar-footer'>
                                    

                                    {cart.checkout === null
                                        ? (
                                            <Loader />
                                        )
                                        : (
                                            <>
                                                <div className='summary'>
                                                    <div className='d-flex justify-content-between'>
                                                        <span>{t("sub_total")}</span>
                                                        <div className='d-flex align-items-center' style={{ fontSize: "14px"}}>
                                                            {setting.setting && setting.setting.currency}
                                                            <span>{(cart.cart?.data?.sub_total.toFixed(setting.setting?.decimal_point))}</span>
                                                        </div>
                                                    </div>

                                                    
                                                </div>

                                                


                                                <div className='button-container'>
                                                    <button type='button' className='view-cart' onClick={() => {
                                                        closeCanvas.current.click()
                                                        navigate('/cart')
                                                    }}>{t("view_cart")}</button>
                                                    <button type='button' className='checkout' onClick={() => {
                                                        closeCanvas.current.click()
                                                        navigate('/checkout')
                                                    }}>{t("proceed_to_checkout")}</button>
                                                </div>
                                            </>)}


                                </div>
                            </>
                        )}
                </>
            )
            }
        </div >

    )
}

export default Cart
